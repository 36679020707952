import React from 'react'
import '../Styles/About.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import FeatureSection from '../Components/FeatureSection'
import BottomCarousel from '../Components/BottomCarousel'
import Testimonials_man from '../assets/images/manImages.jpg'

function About() {

return (
<div className="App">
<header className="header">
    
      <div className='backgroung-image'>
        <Navbar/>
      <div className='header-about-content'>
         <h1>Hear Our Story</h1>
      </div>
      </div>
   </header>
   <main className="main">
   <section className='container about-success'>
             <div className='about-success-work'>
             <h2 className="what-we-dosss"><span className="what-we-dossss">Deciphering </span> our <span className="what-we-dossss">DNA </span> Culture </h2>
            <p>Innovation is not just a buzzword for us; it's ingrained in our DNA. We thrive on pushing boundaries, challenging the status quo, and turning dreams into reality. Our commitment towards delivering trust is what sets us apart in a crowded landscape, and it's what will propel us forward as we navigate seamlessly </p>
            <p>But mere quality standards are not enough. We must also foster a culture of like mindedness, willing to share, sense of belonging, accountability, integrity and inclusiveness. </p>
            <p>We must be willing to take risks, to fail fast and learn faster. We must embrace diversity of thought and perspective, recognizing that our differences are our greatest strength.</p>
            <p>At the heart of BRIX lies a steadfast commitment to excellence in knowledge dissemination. We are not merely purveyors of information; we are architects of transformation, empowering individuals and organizations alike to thrive in an ever-changing landscape.</p>
            <p>Our success is not measured solely by the volume of information transferred but by the depth of understanding imparted. We strive not only to teach but to inspire, to ignite the flames of curiosity and innovation that burn within each of us.</p>
            <p>As we look to the future, let us never lose sight of our core values that drives our excellence. These values are the foundation upon which BRIX is built, brick by brick they will guide us through both the triumphs and the trials that lie ahead.</p>
            <p>Together, let us dare to think boldly.</p>
            <p>Thank you all for your dedication and your unwavering commitment to our shared vision.</p>
             </div>
        </section>
        <section className='container about-success'>
             <div className='about-success-work'>
             <h2 className="what-we-dosss"><span className="what-we-dossss">About </span> our team </h2>
             <h2 className="what-we-dosss"><span className="what-we-dossss">MOSAIC OF CREATIVITY  </span></h2>
                 <p>Our team is a mosaic of skills, knowledge, and experience, encompassing fields such as technology, business strategy, design thinking, project management, and more. This diversity enables us to approach challenges from multiple perspectives and deliver innovative solutions tailored to each client's unique needs.</p>
                 <p>By harnessing the collective intelligence of our team, we amplify our impact and achieve remarkable results for our clients.</p>
                 <p>Our team is dedicated to staying at the forefront of emerging trends, technologies, and best practices. Through ongoing education and professional development, we ensure that our skills remain sharp</p>
                 <p>Innovation is in our DNA. We thrive on pushing the boundaries of what's possible and finding creative solutions to complex problems. Whether it's developing groundbreaking technologies, reimagining business processes, or pioneering new ways of working, we are always at the forefront of innovation.</p>
                 <p>Excellence is not just a goal – it's a standard that we uphold in everything we do. From the quality of our work to the professionalism of our interactions, we strive for excellence in every aspect of our business. By holding ourselves to the highest standards, we ensure that our clients receive nothing but the best.</p>
             </div>
        </section>
        <section className='container about-success'>
             <div className='about-success-work'>
             <h2 className="what-we-dosss"><span className="what-we-dossss">BRICK</span>  BY BRICK</h2>
            <p>BRiX Network Pvt Ltd, established in November 2014, stands as a distinguished Knowledge Transfer Organization and Authorized Training Partner (ATP) of Extreme Networks across PAN India. Renowned for setting benchmarks in business compliance and knowledge transfer, BRiX is committed to delivering unparalleled, future-proof services.
 </p>
            <p>
BRiX Network has strategically allied with leading global life sciences organizations and OEMs, serving as their Authorized Training Partner and Learning Center. This is bolstered by an impressive cadre of Subject Matter Experts, Lead Auditors, and Cyber Security Specialists.
</p>
            <p>Through its subsidiary, Savant Networks Academy, in partnership with SoSE™, BRiX has formed alliances with India's premier science and engineering universities and business schools. This collaboration aims to foster industry-academic synergy by establishing Knowledge Transfer Centers (KTCs), seamlessly connected via Domestic MPLS backhaul, to elevate student skillsets.
</p>
<p>
Moreover, BRiX extends its Domain Compliance Programs and industry best practices to System Integrators, Technology Institutions, Distributors, and OEMs, ensuring comprehensive and cutting-edge service delivery.
</p>
             </div>
        </section>
        <section className="features">
         <div className="mt-5">
            <div className="hwhatwedo">
               <div className="container ">
                  <h2 className="what-we-dos"><span className="what-we-do">What</span> We Do</h2>
                  <hr className="animated-hrs" />
               </div>
            </div>
         </div>
         <FeatureSection/>
      </section>
     {/* Happy Clients Start */}
      {/* Happy Clients END */}
      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}

   </main>
      {/* <!------footer start--------> */}
      <Footer/>
{/* <!------footer end--------> */}
</div>
);
}
export default About;